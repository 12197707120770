import React, { useState } from "react";
import { IInheritHasPerson } from "../../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../../Interfaces/IChildren";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { getChildren } from "../Components/Children/ChildrenAddDialog";
import { Box, Button, Collapse, Tab, Tabs, Typography } from "@mui/material";
import { IRequiredFiles } from "../Components/InheritDocument";
import { checkIsDisabled } from "../../../../../core/getDisabledHelper_Inherit";
import { InheritPerson, getInheritPerson } from "../Components/InheritPerson";
import { IInherit } from "../../../../../../Interfaces/IInherit";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IProps {
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    childrenArray: IChildren[];
    setChildrenArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    handleNext: Function;
    handleBack: Function;
}


const foundParent = (isFirstParent:boolean,personArray:IInheritHasPerson[],childrenArray:IChildren[]) => {
    let foundChildren = childrenArray.find(x => x.Children_idPerson === -1);

    if (foundChildren === undefined) {
        return getInheritPerson(-1,personArray,true);
    }
    else {
        let idPerson = (isFirstParent) ? foundChildren.Parent1_idPerson : Number(foundChildren.Parent2_idPerson);
        return getInheritPerson(idPerson,personArray,false);
    }
}


export const Parents:React.FC<IProps> = (props) => {
    const [value, setValue] = useState(0);
    //
    const [firstParent,setFirstParent] = useState(foundParent(true,props.personArray,props.childrenArray))
    const [secondParent,setSecondParent] = useState(foundParent(false,[...props.personArray,firstParent],props.childrenArray))
    const [documentArray, setDocumentArray] = useState<IPersonHasDocument[]>([]);
    //
    const [childrenObject,setChildrenObject] = useState( {
        ...getChildren(true,-1,props.childrenArray),
        Parent1_idPerson: firstParent.idPerson,
        Parent2_idPerson: secondParent.idPerson,
        Children_idPerson: -1
    } as IChildren);
    //
    const [isDeathFirstParent, setIsDeathFirstParent] = useState(firstParent.DateOfDeath !== null);
    const [isDeathSecondParent, setIsDeathSecondParent] = useState(secondParent.DateOfDeath !== null);
    //
    const [isPageFirstParent,setIsPageFirstParent] = useState(true);

    const getIsDisabled = () => {
        return(
            (isPageFirstParent) 
                ? checkIsDisabled(props.inheritObject,isDeathFirstParent,firstParent)
                : checkIsDisabled(props.inheritObject,isDeathSecondParent,secondParent)
        )
    }


    const handleSubmit = (e: React.FormEvent) => {
        //e.preventDefault();

        if (isPageFirstParent) {

            setIsPageFirstParent(false);
        } else {
        //handleSave();
            let personArray = [...props.personArray];
            let foundFirstParend = props.personArray.find(x => x.idPerson === firstParent.idPerson);
            let foundSecondParend = props.personArray.find(x => x.idPerson === secondParent.idPerson);
            let parentAsChild = props.childrenArray.find(x => x.Children_idPerson === -1);

            if (foundFirstParend === undefined) {
                personArray.push(firstParent);
            } else {
                personArray = personArray.map(x => x.idPerson === firstParent.idPerson ? firstParent : x);
            }

            if (foundSecondParend === undefined) {
                personArray.push(secondParent);
            } else {
                personArray = personArray.map(x => x.idPerson === secondParent.idPerson ? secondParent : x);
            }

            props.setPersonHasDocument([
                ...props.personHasDocument.filter(x => x.idPerson !== firstParent.idPerson && x.idPerson !== secondParent.idPerson),
                ...documentArray
            ])

            props.setPersonArray([
                ...personArray
            ])


            if (parentAsChild === undefined) {
                let tmpNewObject = {
                    Parent1_idPerson: firstParent.idPerson,
                    Parent2_idPerson: secondParent.idPerson,
                    Children_idPerson: -1,
                    isAdopted: false,
                    Marriage_idMarriage: null
                } as IChildren

                props.setChildrenArray([
                    ...props.childrenArray,
                    tmpNewObject
                ])
            }

            if (isDeathFirstParent && isDeathSecondParent) {
                props.handleNext();
            } else {
                props.handleNext(7);
            }
        }    
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const contextTsx = () => {
        if (isPageFirstParent) {
            return(
                <>

                </>
            )
        } else {
            return(
                <>
                    <InheritPerson
                        personArray={props.personArray}
                        key="firstParent-2"
                        currentPerson={secondParent}
                        setCurrentPerson={setSecondParent}
                        inheritObject={props.inheritObject}
                        personHasDocument={documentArray}
                        setPersonHasDocument={setDocumentArray}
                        title="Zweites Elternteil"
                        showDeathSettings
                        requiredFiles={ (isDeathSecondParent) ? [{
                            Title: "Sterbeurkunde",
                            isOptional: false,
                            idPersonDocumentType: 1
                        }] as IRequiredFiles[] : []}
                        setIsDeath={setIsDeathSecondParent}
                    />
                </>
            )
        }
    }


    return(
        <>
            
                <Box sx={{mt: 5}}/>
                <Typography variant="h5">
                    Erfassen der Eltern des Erblassers / der Erblasserin
                </Typography>

                <Box sx={{mt: 5}} />

                <Collapse in={isPageFirstParent} appear={false}>
                    <InheritPerson
                        personArray={props.personArray}
                        key="firstParent-1"
                        currentPerson={firstParent}
                        setCurrentPerson={setFirstParent}
                        inheritObject={props.inheritObject}
                        personHasDocument={documentArray}
                        setPersonHasDocument={setDocumentArray}
                        title="Erstes Elternteil"
                        showDeathSettings
                        requiredFiles={ (isDeathFirstParent) ? [{
                            Title: "Sterbeurkunde",
                            isOptional: false,
                            idPersonDocumentType: 1
                        }] as IRequiredFiles[] : []}
                        setIsDeath={setIsDeathFirstParent}
                    />
                </Collapse>

                <Collapse in={!isPageFirstParent}>
                    <InheritPerson
                        personArray={props.personArray}
                        key="firstParent-2"
                        currentPerson={secondParent}
                        setCurrentPerson={setSecondParent}
                        inheritObject={props.inheritObject}
                        personHasDocument={documentArray}
                        setPersonHasDocument={setDocumentArray}
                        title="Zweites Elternteil"
                        showDeathSettings
                        requiredFiles={ (isDeathSecondParent) ? [{
                            Title: "Sterbeurkunde",
                            isOptional: false,
                            idPersonDocumentType: 1
                        }] as IRequiredFiles[] : []}
                        setIsDeath={setIsDeathSecondParent}
                    />
                </Collapse>

                <Box sx={{mt: 10}} />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => (isPageFirstParent) ? props.handleBack() : setIsPageFirstParent(true)}
                        sx={{ mr: 1 }}
                    >
                        Zurück
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleSubmit} variant="contained" disabled={getIsDisabled()}>
                        Weiter
                    </Button>
                </Box>
                {/*


                <Tabs sx={{mt: 5}} value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Erstes Elternteil" {...a11yProps(0)} />
                    <Tab label="Zweites Elternteil" {...a11yProps(1)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                    <InheritPerson
                        personArray={props.personArray}
                        key="firstParent-1"
                        currentPerson={firstParent}
                        setCurrentPerson={setFirstParent}
                        inheritObject={props.inheritObject}
                        personHasDocument={documentArray}
                        setPersonHasDocument={setDocumentArray}
                        title="Erstes Elternteil"
                        showDeathSettings
                        requiredFiles={ (isDeathFirstParent) ? [{
                            Title: "Sterbeurkunde",
                            isOptional: false,
                            idPersonDocumentType: 1
                        }] as IRequiredFiles[] : []}
                        setIsDeath={setIsDeathFirstParent}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <InheritPerson
                        personArray={props.personArray}
                        key="firstParent-2"
                        currentPerson={secondParent}
                        setCurrentPerson={setSecondParent}
                        inheritObject={props.inheritObject}
                        personHasDocument={documentArray}
                        setPersonHasDocument={setDocumentArray}
                        title="Zweites Elternteil"
                        showDeathSettings
                        requiredFiles={ (isDeathSecondParent) ? [{
                            Title: "Sterbeurkunde",
                            isOptional: false,
                            idPersonDocumentType: 1
                        }] as IRequiredFiles[] : []}
                        setIsDeath={setIsDeathSecondParent}
                    />
                </CustomTabPanel>
                 */}

        </>
    )
}