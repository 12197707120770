import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PurchaseContractRealEstateEdit} from "../Components/RealEstate/PurchaseContractRealEstateEdit";
import {IRealEstate} from "../../../../../Interfaces/IRealEstate";
import {DataGrid, deDE, GridColDef, GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import {Delete, Edit} from "@mui/icons-material";
import { NewRealEstateDialog } from "../Components/RealEstate/NewRealEstateDialog";
import { IPropertyType } from "../../../../../Interfaces/IPropertyType";
import { RealEstatePropertyTypes } from "../Components/RealEstate/RealEstatePropertyTypes";
import { IRealEstateHasPropertyType } from "../../../../../Interfaces/IRealEstateHasPropertyType";
import { IRealEstateHasInventoryOption } from "../../../../../Interfaces/IRealEstateHasInventoryOption";
import { IInventoryOption } from "../../../../../Interfaces/IInventoryOption";
import { IRealEstateUseType } from "../../../../../Interfaces/IRealEstateUseType";
import { RealEstateEdit } from "../Components/RealEstate/RealEstateEdit";
import { IPurchaseContractHasPerson } from "../../../../../Interfaces/IPerson";
import { IConfig } from "../../../../../Interfaces/IConfig";


interface IProps {
    configObject: IConfig;
    handleBack: Function;
    handleNext: Function;
    realEstateArray: IRealEstate[];
    setRealEstateArray: Function;
    realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
    setRealEstateHasPropertyTypeArray: Function
    propertyTypeArray: IPropertyType[];
    realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
    setRealEstateHasInventroyOptionArray: Function
    inventoryOptionArray: IInventoryOption[];
    realEstateUseTypeArray: IRealEstateUseType[];
    personArray: IPurchaseContractHasPerson[];
    setPersonArray: Function;
}


export const PurchaseContractRealEstate: React.FC<IProps> = (props) => {

    const [idRealEstate, setIdRealEstate] = useState<number | null>(null);

    const handleClose = () => {
        setIdRealEstate(null)
    }

    const columns: GridColDef[] = [
        {field: 'idRealEstate', headerName: 'ID', width: 90},
        {
            field: 'Price',
            headerName: 'Kaufpreis',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => Number(params.row.Price).toLocaleString("de-DE", { style: "currency", currency: "EUR" })
        },
        {
            field: 'PropertyType',
            headerName: 'Grundstücksarten',
            width: 250,
            valueGetter: (params: GridValueGetterParams) => {
                let filteresId = props.realEstateHasPropertyTypeArray.filter(x => x.idRealEstate === Number(params.id)).map(x => x.idPropertyType);
                return props.propertyTypeArray.filter(x => filteresId.indexOf(x.idPropertyType) > -1 ).map(x => x.PropertyType).join(", ") 
            }
        },
        {
            field: 'adress',
            headerName: 'Adresse',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.Street || ''} ${params.row.StreetNr || ''} ${params.row.StreetAditional || ''}, ${params.row.City || ''} ${params.row.Postcode || ''}`,
        },

        {
            field: 'Actions',
            headerName: 'Aktionen',
            width: 150,
            renderCell: (params: GridRenderCellParams) =>
                <>
                    <IconButton onClick={() => setIdRealEstate(params.row.idRealEstate)}><Edit/></IconButton>
                    <IconButton onClick={() => props.setRealEstateArray(props.realEstateArray.filter(x => x.idRealEstate !== params.row.idRealEstate))}><Delete/></IconButton>
                </>
        },
    ];


    return (
        <>
            <Box sx={{mt: 5}}/>


            <Typography variant="h4" sx={{mb: 3}}>
                Vertragsgegenstände
                    
                    <NewRealEstateDialog
                        configObject={props.configObject}
                        realEstateArray={props.realEstateArray}
                        setRealEstateArray={props.setRealEstateArray}
                        realEstateHasPropertyTypeArray={props.realEstateHasPropertyTypeArray}
                        setRealEstateHasPropertyTypeArray={props.setRealEstateHasPropertyTypeArray}
                        propertyTypeArray={props.propertyTypeArray}
                        realEstateHasInventroyOptionArray={props.realEstateHasInventroyOptionArray}
                        setRealEstateHasInventroyOptionArray={props.setRealEstateHasInventroyOptionArray}
                        inventoryOptionArray={props.inventoryOptionArray}
                        realEstateUseTypeArray={props.realEstateUseTypeArray}
                        personArray={props.personArray}
                        setPersonArray={props.setPersonArray}
                    />

            </Typography>

            <Dialog open={idRealEstate !== null} onClose={() => setIdRealEstate(null)} maxWidth="lg" fullWidth>
                <DialogTitle>Objekt Bearbeiten</DialogTitle>
                <DialogContent>
                    <RealEstateEdit 
                        configObject={props.configObject}
                        idRealEstate={idRealEstate}
                        setIdRealEstate={setIdRealEstate}
                        realEstateArray={props.realEstateArray}
                        setRealEstateArray={props.setRealEstateArray}
                        inventoryOptionArray={props.inventoryOptionArray}
                        propertyTypeArray={props.propertyTypeArray}
                        realEstateHasInventroyOptionArray={props.realEstateHasInventroyOptionArray}
                        realEstateHasPropertyTypeArray={props.realEstateHasPropertyTypeArray}
                        realEstateUseTypeArray={props.realEstateUseTypeArray}
                        setRealEstateHasInventroyOptionArray={props.setRealEstateHasInventroyOptionArray}
                        setRealEstateHasPropertyTypeArray={props.setRealEstateHasPropertyTypeArray}
                        personArray={props.personArray}
                        setPersonArray={props.setPersonArray}
                    />
                </DialogContent>
            </Dialog>




            <br/>
            <Box sx={{height: 400, width: '100%'}}>
                <DataGrid
                    sx={{mt: 2}}
                    autoHeight
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={props.realEstateArray}
                    columns={columns}
                    getRowId={(row) => row.idRealEstate}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                />
            </Box>



            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleBack()}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                 type="submit"  
                 variant="contained" 
                 onClick={() => props.handleNext()} 
                 disabled={props.realEstateArray.length === 0}
                >
                    Weiter
                </Button>
            </Box>
        </>
    )
}