import { Box, Button, Typography } from "@mui/material";
import React from "react";

interface IProps {
  currentStep: number;
  setcurrentStep: Function;
  handleNext: Function;
}

export const Start: React.FC<IProps> = (props) => {
  return (
    <>
      <Box sx={{ mt: 5 }} />
      <Typography variant="h4" sx={{ mb: 3 }}>
        Grundschuld (Bestellung oder Löschung)
      </Typography>

      <Typography>
        Herzlich Willkommen! <br />
        Sie möchten eine Grundschuld bestellen oder löschen? Mit unserem Dialog
        können Sie dies in wenigen Schritten vorbereiten. Wenn Sie Ihre Daten
        eingereicht haben, erstellen wir Zügig einen Vertragsentwurf für Sie.
        <p />
        Brauchen Sie zwischendurch eine Pause? Sie können Ihre Eingaben
        jederzeit über das Feld „Sichern“ exportieren und speichern.
        Führen Sie Ihre Eingaben dann über die Klickfläche „Wiederherstellung“ fort.
        <p />
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          disabled={props.currentStep === 0}
          onClick={() => {}}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={() => props.handleNext()} variant="contained">
          Weiter
        </Button>
      </Box>
    </>
  );
};
