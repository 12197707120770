import React, { useState } from "react";
import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";
import { Grid, TextField, Typography } from "@mui/material";
import DistrictCourtSearch from "../../../../../generic/DistrictCourtSearch";
import { IDistrictCourt } from "../../../../../../Interfaces/IDistrictCourt";

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const RealEstateLandRegister: React.FC<IProps> = (props) => {
  const handleDistrictCourt = (localObject: IRealEstate) => {
    if (
      localObject.idDistrictCourt !== null &&
      localObject.idDistrictCourt > 0
    ) {
      return props.currentObject.districtCourt;
    } else {
      return null;
    }
  };

  const setDistrictCourtObjectWrapper = (
    districtCourtObject: IDistrictCourt | null | undefined
  ) => {
    if (districtCourtObject != undefined) {
      props.setCurrentObject({
        ...props.currentObject,
        idDistrictCourt: districtCourtObject.idDistrictCourt,
        districtCourt: districtCourtObject,
      } as IRealEstate);
    }
  };

  const [districtCourtObject, setDistrictCourtObject] = useState<
    IDistrictCourt | null | undefined
  >(handleDistrictCourt(props.currentObject));

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 1 }}
      >
        Grundbuchinformationen
        <br />
        <Typography>
          Falls Ihnen die Grundbuchinformationen bereits vorliegen, tragen Sie
          diese gerne ein. Andernfalls können diese Informationen zu einem
          späteren Zeitpunkt erfasst werden.
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DistrictCourtSearch
            districtCourtObject={districtCourtObject}
            setDistrictCourtObject={setDistrictCourtObjectWrapper}
            setDistrictCourt={setDistrictCourtObject}
          />
        </Grid>
        <Grid item sm={8}>
          <TextField
            label="Grundbuch"
            size="small"
            fullWidth
            value={props.currentObject.LandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandRegister: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            label="Blatt"
            size="small"
            fullWidth
            value={props.currentObject.SheetLandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                SheetLandRegister: event.target.value,
              })
            }
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label="Flurstück"
            size="small"
            fullWidth
            value={props.currentObject.LandParcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandParcel: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label="Flur"
            size="small"
            fullWidth
            value={props.currentObject.Parcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Parcel: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
