import { Alert, AppBar, Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, StepTypeMap, Stepper, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { useEffect, useState } from "react";
import { IConfig } from "../../Interfaces/IConfig";
import { Menu, ScreenRotation, StayCurrentLandscape } from "@mui/icons-material";


interface IProps {
    configObject: IConfig;
    title: string;
    currentPage: number;
    stepperArray: React.ReactElement[];
    headerButtonArray?: React.ReactElement[];
    children: React.ReactNode;
    txt?: string;
    window?: () => Window;
    disableHorizontalWarning?: boolean;
}
const drawerWidth = 240;

export const DialogFrame:React.FC<IProps> = ({configObject,title,currentPage, stepperArray, headerButtonArray, children, txt, window, disableHorizontalWarning}) => {
    const isDesktop = useMediaQuery('(min-width:900px)'); 
    const isMinWidth = useMediaQuery('(min-width:500px)'); 
    // XS: 0px und md ab 900px
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isOpenWarningHorizontal,setIsOpenWarningHorizontal] = useState(!(isDesktop || isMinWidth) && (disableHorizontalWarning === undefined || disableHorizontalWarning === false));
    const [wasWarningNotTriggert,setWasWarningNotTriggert] = useState(!isOpenWarningHorizontal);
  
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };
  
    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Menü
        </Typography>
        <Divider />
        <List>
            {(headerButtonArray !== undefined) && headerButtonArray.map(x => x)}
        </List>
      </Box>
    );
  
    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
      
      if (wasWarningNotTriggert && !(isDesktop) && (disableHorizontalWarning === undefined || disableHorizontalWarning === false)) {
        // 
        if (isMinWidth) {
          setIsOpenWarningHorizontal(false);
        } else {
          setIsOpenWarningHorizontal(true);
          setWasWarningNotTriggert(false);
        }
      }
    },[isMinWidth])




    if (isDesktop) {
        return(
            <>
                <Box sx={{display: 'flex'}}>
                    <Drawer
                        sx={{
                            width: 250,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 250,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={true}
                    >
                        <Box sx={{p: 2}}>
                            <Stepper activeStep={currentPage} orientation="vertical">
                                {stepperArray.map(x => x)}
                            </Stepper>
                        </Box>
                    </Drawer>

                    <Box sx={{flexGrow: 1, width: {sm: `calc(100% - ${250}px)`}}}>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    {title}
                                </Typography>
                                {headerButtonArray}
                            </Toolbar>
                        </AppBar>

                        <Box sx={{p: 3}}>
                            <img width={250} src={`data:${configObject.logo_mime};base64,${configObject.logo}`}/>
                            {children}
                        </Box>
                    </Box>


                    <Drawer
                        sx={{
                            width: 250,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 250,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="right"
                        open={true}
                    >
                        <Box sx={{p: 2}}>
                            {txt}
                        </Box>
                    </Drawer>
                </Box>
            </>
        )
    }
    else {
        return (
            <>
            <Dialog open={isOpenWarningHorizontal} onClose={() => setIsOpenWarningHorizontal(false)}>
              <DialogContent>
                <Alert severity="info">
                  <ScreenRotation/>
                  <br/>
                  Für eine optimale Darstellung dieser Seite wird empfohlen, dass sie Ihr Endgerät drehen, sodass Sie die horizontale Ansicht erhalten.
                  <StayCurrentLandscape/>
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsOpenWarningHorizontal(false)} variant="contained">Okay</Button>
              </DialogActions>
            </Dialog>

            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar component="nav">
                <Toolbar variant="dense">
                  {(headerButtonArray !== undefined) &&
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { md: 'none' } }}
                    >
                      <Menu />
                    </IconButton>
                  }

                  <Typography
                    variant="h6"
                    component="div"
                  >
                    {title}
                  </Typography>
                </Toolbar>
              </AppBar>
              <nav>
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                >
                  {drawer}
                </Drawer>
              </nav>
              <Box component="main" sx={{ m: 3 }} >
                <Toolbar />
                <Box sx={{p: 2}}>
                    <Stepper activeStep={currentPage} orientation="vertical">
                        {stepperArray.map(x => x)}
                    </Stepper>
                </Box>
                {children}
              </Box>
            </Box>
            </>
          );
    }
}