import React, { useEffect, useState } from "react";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { IMortgagePerson } from "../../../../../../Interfaces/IPerson";
import { Box, Button } from "@mui/material";
import { MortgagePerson, getMortgagePerson } from "../Components/Owner/MortgagePerson";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";
import { MortgageOwnerTable } from "../Components/Owner/MortgageOwnerTable";

interface IProps {
    personArray: IMortgagePerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    mortgageObject: IMortgage;
    setMortgageObject: Function;
    handleNext: Function;
    handleBack: Function;
}

export const MortageBaseInformation: React.FC<IProps> = (props) => {
    const [ownerArray, setOwnerArray] = useState(props.personArray);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if(ownerArray.length === 0)
        {
            setIsDisabled(true)
        }
        else{
            setIsDisabled(false)
        }
        props.setPersonArray(ownerArray);
    }, [ownerArray]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        props.setPersonArray(ownerArray);
        props.handleNext();
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 5 }} />
                <MortgageOwnerTable
                    personArray={ownerArray}
                    setPersonArray={setOwnerArray}
                    personHasDocument={props.personHasDocument}
                    setPersonHasDocument={props.setPersonHasDocument}
                    mortgageObject={props.mortgageObject}
                    setMortgageObject={props.setMortgageObject}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => props.handleBack()}
                        sx={{ mr: 1 }}
                    >
                        Zurück
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="submit" disabled={isDisabled} variant="contained">
                        Weiter
                    </Button>
                </Box>
            </form>
        </>
    )
}
