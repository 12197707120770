import React, { useEffect, useState } from "react";
import { IRealEstateHasInventoryOption } from "../../../../../../Interfaces/IRealEstateHasInventoryOption";
import { IInventoryOption } from "../../../../../../Interfaces/IInventoryOption";
import { Collapse, Grid, MenuItem, TextField } from "@mui/material";


interface IProps {
    currentObject: IRealEstateHasInventoryOption;
    setCurrentObject: Function;
    inventoryOptionArray: IInventoryOption[];
    marginTop?: number;
}


export const searchRealEstateInventoryOption = (idRealEstate_has_InventoryOption:number,idRealEstate:number,realEstateHasInventroyOptionArray:IRealEstateHasInventoryOption[],forceNew = false) => {
    let foundObject = realEstateHasInventroyOptionArray.find(x => x.idRealEstate_has_InventoryOption === idRealEstate_has_InventoryOption);

    if (!forceNew && foundObject !== undefined) {
        return foundObject
    } else {
        let minId = Math.min(...realEstateHasInventroyOptionArray.map(x => x.idRealEstate_has_InventoryOption)) -1;

        if (minId > -1) {
            minId = -1;
        }

        return {
            idRealEstate_has_InventoryOption: minId,
            idRealEstate: idRealEstate,
            idInventoryOption: -1,
            Amount: 1,
            Description: null,
            Title: ""
        } as IRealEstateHasInventoryOption
    }
}



export const RealEstateInventoryOptionEdit:React.FC<IProps> = props => {
    const [currentInventoryOption,setCurrentInventoryOption] = useState<IInventoryOption|undefined>(props.inventoryOptionArray.find(x => props.currentObject.idInventoryOption));
    //


    return(
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TextField
                        label="Anlage bzw. Inventar"
                        size="small"
                        fullWidth
                        required
                        value={(props.currentObject.idInventoryOption < 1) ? "" : props.currentObject.idInventoryOption}
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                let idInventoryOption = Number(event.target.value);
                                let testObject = props.inventoryOptionArray.find(x => x.idInventoryOption === idInventoryOption);

                                setCurrentInventoryOption(testObject);

                                if (testObject !== undefined) {
                                    props.setCurrentObject({
                                        ...props.currentObject,
                                        idInventoryOption: idInventoryOption,
                                        Title: testObject.InventoryOption,
                                        Amount: 1,
                                        Description: null
                                    })
                                }
                            }
                        }
                        select
                    >

                        { props.inventoryOptionArray.map(x => 
                            <MenuItem
                             key={`idInventoryOption-${x.idInventoryOption}`}
                             value={x.idInventoryOption}
                            >
                                {x.InventoryOption}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>

                <Grid item sm={12}>
                    <Collapse in={currentInventoryOption !== undefined && Boolean(currentInventoryOption.enableTextField)}>
                        <TextField
                            label="Bezeichnung"
                            size="small"
                            fullWidth
                            value={props.currentObject.Title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                                props.setCurrentObject({
                                    ...props.currentObject, 
                                    Title: event.target.value
                                } as IRealEstateHasInventoryOption)
                            }
                            error={props.currentObject.Title === ""}
                        />
                    </Collapse>
                </Grid>

                <Grid item sm={12}>
                    <TextField
                        label="Anzahl"
                        size="small"
                        type="number"
                        fullWidth
                        value={props.currentObject.Amount}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                            props.setCurrentObject({
                                ...props.currentObject, 
                                Amount: (event.target.value === "") ? 0 : Number(event.target.value)
                            })
                        }
                    />
                </Grid>

                <Grid item sm={12}>
                    <TextField
                        label="Beschreibung"
                        size="small"
                        fullWidth
                        value={(props.currentObject.Description === null) ? "" : props.currentObject.Description}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                            props.setCurrentObject({
                                ...props.currentObject, 
                                Description: (event.target.value === "") ? null : event.target.value
                            } as IRealEstateHasInventoryOption)
                        }
                        multiline
                        rows={3}
                    />
                </Grid>
            </Grid>
        </>
    )
}