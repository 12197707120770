import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";

interface IProps {
    handleBack: Function;
    handleNext: Function;
    mortgageObject: IMortgage;
    setMortgageObject: Function;
}

export const MortgageGeneral: React.FC<IProps> = (props) => {
    const [beneficiary, setBeneficiary] = useState<boolean>(props.mortgageObject.Beneficiary === "private");
    const [propertyBuy, setPropertyBuy] = useState<boolean>(props.mortgageObject.PropertyForBuying);
    const [propertyOwned, setPropertyOwned] = useState<boolean>(props.mortgageObject.PropertyOwned);

    useEffect(() => {
        props.setMortgageObject({
            ...props.mortgageObject,
            Beneficiary: beneficiary ? "private" : "institution",
            PropertyForBuying: propertyBuy,
            PropertyOwned: propertyOwned
        });
    }, [beneficiary, propertyBuy, propertyOwned]);

    const handleBeneficiaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBeneficiary(event.target.value === "private");
    };

    const handlePropertyBuyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertyBuy(event.target.value === "yes");
    };

    const handlePropertyOwnedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertyOwned(event.target.value === "yes");
    };

    return (
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4">
                Grundpfand
            </Typography>
            <Typography sx={{ mb: 3 }}>
                Für wen soll die Grundschuld bestellt werden?
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="for-who"
                    name="who"
                    value={beneficiary ? "private" : "institution"}
                    onChange={handleBeneficiaryChange}
                >
                    <FormControlLabel value="institution" control={<Radio />} label="Eine Bank (auch [Bau-] Sparkasse) oder eine Versicherung" />
                    <FormControlLabel value="private" control={<Radio />} label="Eine Privatperson oder ein sonstiges Unternehmen" />
                </RadioGroup>
            </FormControl>
            <Box sx={{ mt: 5 }} />
            <Typography sx={{ mb: 3 }}>
                Wird die Grundschuld zur Finanzierung eines Immobilienkaufs benötigt?
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="property"
                    name="property-buy"
                    value={propertyBuy ? "yes" : "no"}
                    onChange={handlePropertyBuyChange}
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                    <FormControlLabel value="no" control={<Radio />} label="Nein" />
                </RadioGroup>
            </FormControl>
            {propertyBuy && (
                <>
                    <Box sx={{ mt: 5 }} />
                    <Typography sx={{ mb: 3 }}>
                        Ist der Immobilienkauf bereits erfolgt?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="property-owned"
                            name="property-owned"
                            value={propertyOwned ? "yes" : "no"}
                            onChange={handlePropertyOwnedChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                            <FormControlLabel value="no" control={<Radio />} label="Nein" />
                        </RadioGroup>
                    </FormControl>
                    {propertyOwned && (
                        <>
                            <Box sx={{ mt: 5 }} />
                            <Typography sx={{ mb: 3 }}>
                                Geben Sie bitte die Urkundenverzeichnisnummer ein (sog. UVZ-Nummer).
                            </Typography>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    label="Urkundenverzeichnisnummer"
                                    size="small"
                                    fullWidth
                                    value={props.mortgageObject.DocumentIndexNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, DocumentIndexNumber: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Box sx={{ mt: 5 }} />
                            <Typography sx={{ mb: 3 }}>
                                Geben Sie die Vorgangsnummer (sechsstellig, befindet sich ausgegraut auf der ersten Seite des Entwurfs) an.
                            </Typography>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    label="Vorgangsnummer"
                                    size="small"
                                    fullWidth
                                    value={props.mortgageObject.OperationNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, OperationNumber: event.target.value })}
                                    required
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
            <Box sx={{ mt: 5 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleBack()}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => props.handleNext()}
                >
                    Weiter
                </Button>
            </Box>
        </>
    );
}
