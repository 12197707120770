import React, { useEffect, useState } from "react";
import { ICompanyFormation } from "../../../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../../Interfaces/ICompanyFormationHasShareHolder";
import { Box, Button, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ShareholderWithoutShares } from "../../../../ShareHolder/ShareholderWithoutShares";
import { RABoardMemeberRow } from "./RABoardMemeberRow";
import { useTranslation } from "react-i18next";
import { checkTranslation } from "../../../../core/standardFunctions";

interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    setTxt: Function;
    currentStep: number;
    handleBack: Function;
    handleNext: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    setCompanyFormationShareArray: Function;
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;

    //Für CompanyChange Buttons deaktiveren
    buttonsDisabled?: boolean;
}


export const RABoardMemebers:React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [hasSoftWarning,setHasSoftWarning] = useState();

    return(
        <>
            <Box sx={{mt: 5}} />
            <Typography variant="h4" sx={{mb: 3}}>{checkTranslation(t, "headline_dialog_ra_board", "Vorstand")}</Typography>
            <Typography variant="h6" sx={{mb: 3}}>
            {checkTranslation(t, "headline_dialog_ra_board_members", "Vorstandsmitglieder")}
            </Typography>

            <ShareholderWithoutShares
                idShareHolderType={5}
                titleTablePerson={checkTranslation(t, "board_ra_table_title", "Vorstandsmitglied") }
                disablePersonSelectCompanyType={true}
                minShareHolder={1}
                warningText={checkTranslation(t, "warning_ra_board_table", "Es muss mindestens eine Person im Vorstand sein!")} 

                companyFormationObject={props.companyFormationObject}
                setCompanyFormationObject={props.setCompanyFormationObject}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
                companyFormationShareArray={props.companyFormationShareArray}
                setCompanyFormationShareArray={props.setCompanyFormationShareArray}
                shareHolderRelation={props.shareHolderRelation}
                setShareHolderRelation={props.setShareHolderRelation}

                isTranslationEnabled={true}
            />


            <Collapse in={props.shareHolderRelation.length > 0}>
                <Box sx={{mt: 10}}/>
                <Typography variant="h6" sx={{mb: 3}}>
                {checkTranslation(t, "board_functions", "Vorstandsfunktionen")} 
                </Typography>
                {checkTranslation(t, "board_functions_header", "Der Vorstand im Sinne des § 26 BGB besteht aus...")}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: 300}}>{checkTranslation(t, "board_ra_table_title", "Vorstandsmitglied")}</TableCell>
                            <TableCell>{checkTranslation(t, "board_function", "Vorstandsfunktion")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(props.shareHolderRelation.length == 0)
                            ? <TableRow><TableCell colSpan={2}><i>{checkTranslation(t, "board_ra_table_empty", "Keine Vorstandsmitglieder vorhanden")}</i></TableCell></TableRow>
                            : props.shareHolderRelation.map(x =>
                                <RABoardMemeberRow
                                    key={`RABoardMemeberRow-${x.idShareHolder}`}
                                    shareHolderRelation={x}
                                    shareHolderArray={props.shareHolderArray}
                                    shareHolderRelationArray={props.shareHolderRelation}
                                    setShareHolderRelation={props.setShareHolderRelation}
                                />
                            )
                        }
                    </TableBody>
                </Table>
            </Collapse>



            {(props.buttonsDisabled === undefined || props.buttonsDisabled === false) &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                    <Button
                        variant="outlined"
                        disabled={props.currentStep === 0}
                        onClick={() => props.handleBack()}
                        sx={{ mr: 1 }}
                    >
                        {checkTranslation(t, "button_ra_back", "Zurück")}
                    </Button>



                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" onClick={() => props.handleNext()} disabled={props.shareHolderArray.length < 1}>
                        {checkTranslation(t, "button_ra_next", "Weiter")}
                    </Button>
                </Box>
            }
        </>
    )
}
