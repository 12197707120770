import React, { useEffect, useState } from "react";
import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";
import { IPropertyType } from "../../../../../../Interfaces/IPropertyType";
import { DataGrid, GridColDef, GridInputRowSelectionModel, GridRowSelectionModel, deDE } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { IRealEstateHasPropertyType } from "../../../../../../Interfaces/IRealEstateHasPropertyType";



interface IProps {
    idRealEstate: number;
    realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
    setRealEstateHasPropertyTypeArray: Function
    propertyTypeArray: IPropertyType[];
    marginTop?: number;
}


const columnsPropertyType: GridColDef[] = [
    {
        field: 'PropertyType',
        headerName: 'Beschreibung',
        description: 'Mögliche Grundstücksarten',
        sortable: false,
        flex: 1,
    },
];



export const RealEstatePropertyTypes:React.FC<IProps> = (props) => {
    //const [selectedPropertyModel,setSelectedPropertyModel] = useState<GridInputRowSelectionModel>(props.realEstateHasPropertyTypeArray.map(x => x.idPropertyType))

    const handleSelectModelChange = (selectedPropertyModel:GridInputRowSelectionModel) => {
        let tmpArray:IRealEstateHasPropertyType[] = [];

        if (Array.isArray(selectedPropertyModel)) {
            selectedPropertyModel.map(x => 
                tmpArray.push({
                    idRealEstate: props.idRealEstate,
                    idPropertyType: x
                } as IRealEstateHasPropertyType)
            )
            props.setRealEstateHasPropertyTypeArray([...tmpArray]);
        }
    }


    return(
        <>
            <Typography variant="h5" sx={{mt: (props.marginTop === undefined) ? 2 : props.marginTop, mb: 1}}>
                Grundstücksarten
                <br/>
                <Typography>
                    Bitte wählen Sie die passende Beschreibung Ihres Grundstücks aus. 
                </Typography>
            </Typography>

            <DataGrid
                sx={{mt: 2}}
                autoHeight
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={props.propertyTypeArray}
                columns={columnsPropertyType}
                getRowId={(row) => row.idPropertyType}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                rowSelectionModel={props.realEstateHasPropertyTypeArray.map(x => x.idPropertyType)}
                onRowSelectionModelChange={handleSelectModelChange}
            />
        </>
    )
}


