import React from "react";
import { IMortgagePerson } from "../../../../../../../Interfaces/IPerson";
import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IContactFullObject } from "../../../../../../../Interfaces/IContact";


interface IProps {
    beneficiary: any;
    contactObject: IContactFullObject;
}


export const MortgagePersonSummary:React.FC<IProps> = (props) => {
    return(
        <>
            {props.beneficiary &&
                <>
                    <Grid container spacing={2} sx={{ml: 0, mt: 2}}>
                        <Typography variant="h6" sx={{mt: 5}}>Begünstigter</Typography>
                        <Grid item sm={12}><hr /></Grid>
                        {props.beneficiary &&
                            <>
                                <Grid item sm={4}><b>Unternehmen oder Privatperson?</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.isCompany ? "Unternehmen" : "Privatperson"}</Grid>
                            </>
                        }

                        {props.beneficiary.isCompany && props.beneficiary.Company && props.beneficiary.Company.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Firmenname:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Company}</Grid>
                            </>
                        }

                        {props.beneficiary.FirstName && props.beneficiary.FirstName.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Vorname:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.FirstName}</Grid>
                            </>
                        }

                        {props.beneficiary.LastName && props.beneficiary.LastName.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Nachname:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.LastName}</Grid>
                            </>
                        }

                        {props.beneficiary.Email && props.beneficiary.Email.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Email:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Email}</Grid>
                            </>
                        }

                        {props.beneficiary.PhoneNumber && props.beneficiary.PhoneNumber.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Telefon:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Phone}</Grid>
                            </>
                        }

                        {props.beneficiary.Street && props.beneficiary.Street.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Straße:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Street}</Grid>
                            </>
                        }

                        {props.beneficiary.StreetNr && props.beneficiary.StreetNr.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Hausnummer:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.StreetNr}</Grid>
                            </>
                        }

                        {props.beneficiary.Postcode && props.beneficiary.Postcode.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Postleitzahl:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Postcode}</Grid>
                            </>
                        }

                        {props.beneficiary.City && props.beneficiary.City.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Ort:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.City}</Grid>
                            </>
                        }

                        {props.beneficiary.Country && props.beneficiary.Country.length > 0 &&
                            <>
                                <Grid item sm={4}><b>Land:</b></Grid>
                                <Grid item sm={8}>{props.beneficiary.Country}</Grid>
                            </>
                        }
                    </Grid>
                </>
            }

            { /* props.contactObject &&
                <>
                    <Grid container spacing={2} sx={{ml: 0, mt: 2}}>
                        <Typography variant="h6" sx={{mt: 5}}>Kontaktdaten</Typography>
                        <Grid item sm={12}><hr /></Grid>
                        {
                            props.contactObject.FirstName &&
                            <>
                                <Grid item sm={4}><b>Vorname:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.FirstName}</Grid>
                            </>
                        }

                        {
                            props.contactObject.LastName &&
                            <>
                                <Grid item sm={4}><b>Nachname:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.LastName}</Grid>
                            </>
                        }

                        {
                            props.contactObject.Email &&
                            <>
                                <Grid item sm={4}><b>Email:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.Email}</Grid>
                            </>
                        }

                        {
                            props.contactObject.CompanyObject.PhoneNumber &&
                            <>
                                <Grid item sm={4}><b>Telefon:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.PhoneNumber}</Grid>
                            </>
                        }

                        {
                            props.contactObject.CompanyObject && props.contactObject.CompanyObject.Street &&
                            <>
                                <Grid item sm={4}><b>Straße:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.CompanyObject.Street}</Grid>
                            </>
                        }

                        {
                            props.contactObject.CompanyObject && props.contactObject.CompanyObject &&
                            <>
                                <Grid item sm={4}><b>Hausnummer:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.CompanyObject.StreetNr}</Grid>
                            </>
                        }

                        {
                            props.contactObject.CompanyObject && props.contactObject.CompanyObject &&
                            <>
                                <Grid item sm={4}><b>Postleitzahl:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.CompanyObject.Postcode}</Grid>
                            </>
                        }

                        {
                            props.contactObject.CompanyObject && props.contactObject.CompanyObject.City &&
                            <>
                                <Grid item sm={4}><b>Ort:</b></Grid>
                                <Grid item sm={8}>{props.contactObject.CompanyObject.City}</Grid>
                            </>
                        }
                    </Grid>
                </>
            */}
        </>
    )
}