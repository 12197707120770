import React, {useEffect, useState} from "react";
import {ICompanyFormation, ICompanyFormationFullObject} from "../../../../../Interfaces/ICompanyFormation";
import {IShareHolderFullObject} from "../../../../../Interfaces/IShareHolder";
import {ICompanyFormationShare} from "../../../../../Interfaces/ICompanyFormationShare";
import {ICompanyFormationHasShareHolder} from "../../../../../Interfaces/ICompanyFormationHasShareHolder";
import {ICompanyFormationAnswer} from "../../../../../Interfaces/ICompanyFormationAnswer";
import {ICompanyFormationQuestion} from "../../../../../Interfaces/ICompanyFormationQuestion";
import {ICompanyFormationOption} from "../../../../../Interfaces/ICompanyFormationOption";
import {
    Alert,
    Box, Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {checkString} from "../../../../core/standardFunctions";
import {CompanyFormationAnswerSummary} from "../../Founding/Childs/Summary/CompanyFormationAnswerSummary";
import {IContact, IContactFullObject} from "../../../../../Interfaces/IContact";
import {ICompany} from "../../../../../Interfaces/ICompany";
import {ClientInformation} from "../../../../generic/ClientInformation";
import {academicTitleArray} from "../../../../../StaticArraies/academicTitleArray";
import {genderArray} from "../../../../../StaticArraies/genderArray";
import {Search} from "@mui/icons-material";
import {ShareHolderEdit} from "../../Founding/Childs/ShareHolder/ShareHolderEdit";
import {IDocument} from "../../../../../Interfaces/IDocument";
import {DocumentOverview} from "../../../../Document/DocumentOverview";
import {IPostcode} from "../../../../../Interfaces/IPostcode";
import {ILegalTransactionFullObject} from "../../../../../Interfaces/ILegalTransaction";
import {useTranslation} from "react-i18next";
import { IConfig } from "../../../../../Interfaces/IConfig";


export interface IProps {
    configObject: IConfig;
    handleBack: Function;
    handleNext: Function;
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    companyFormationAnswerArray: ICompanyFormationAnswer[];
    companyFormationQuestionArray: ICompanyFormationQuestion[];
    companyFormationOptionArray: ICompanyFormationOption[];
    documentArray: IDocument[];
    setDocumentArray: Function;
}


export const RASummary: React.FC<IProps> = (props) => {
    const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
    //
    const [postCodeObject, setPostCodeObject] = useState<IPostcode | undefined>(undefined);
    const [addressPostCodeObject, setAddressPostCodeObject] = useState<IPostcode | undefined>(undefined);

    const [personObject, setPersonObject] = useState<IContact>({
        idContact: -1,
        idCompany: -1,
        idGender: 1,
        idAcademicTitle: 1,
        FirstName: "",
        LastName: "",
        Email: ""
    } as IContact);

    const [companyObject, setCompanyObject] = useState<ICompany>({
        idCompany: -1,
        Company: "",
        idBusinessForm: 1,
        idPostcode: null,
        Street: "",
        StreetNr: "",
        StreetAditional: "",
        PhoneNumber: "",
        Website: "",
        RegisterCourt: null,
        RegisterNumber: null,
        TaxNumber: null,
        SalesTaxNumber: null
    })

    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);


    useEffect(() => {
        if (props.companyFormationObject.idPostcode > 0) {
            fetch(`/api/postcode/${props.companyFormationObject.idPostcode}`, {
                headers: {
                    "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw Error(`ERROR GET: ${res.status}`)
                    }
                })
                .then(res => {
                    setPostCodeObject(res);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [props.companyFormationObject.idPostcode])


    useEffect(() => {
        if (props.companyFormationObject.Postcode_AddressidPostcode !== null && props.companyFormationObject.Postcode_AddressidPostcode > 0) {
            fetch(`/api/postcode/${props.companyFormationObject.Postcode_AddressidPostcode}`, {
                headers: {
                    "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw Error(`ERROR GET: ${res.status}`)
                    }
                })
                .then(res => {
                    setAddressPostCodeObject(res);
                })
                .catch((error) => {
                    console.error(error);
                })
        } else {
            setAddressPostCodeObject(undefined);
        }
    }, [props.companyFormationObject.Postcode_AddressidPostcode])


    async function handleSubmit() {
        setIsLoading(true);
        //handleNext();
        let legalTransactionFullObject = {
            idLegalTransaction: -1,
            idLegalTransactionState: 1,
            idLegalTransactionType: 6,
            idContact: -1,
            ContactObject: {
                ...personObject,
                CompanyObject: companyObject
            } as IContactFullObject,
            CompanyFormation: {
                ...props.companyFormationObject,
                ShareHolderArray: props.shareHolderArray,
                CompanyFormationShareArray: props.companyFormationShareArray,
                CompanyFormationHasShareHolderArray: props.shareHolderRelation,
                CompanyFormationAnswerArray: props.companyFormationAnswerArray,
                DocumentArray: props.documentArray
            } as ICompanyFormationFullObject
        } as ILegalTransactionFullObject
        fetch("/api/legaltransaction/createCompanyFomration", {
            method: "POST",
            headers: {
                "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                "Content-type": "application/json"
            },
            body: JSON.stringify(legalTransactionFullObject)
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw Error(`ERROR GET: ${res.status}`)
                }
            })
            .then(res => {
                setIsLoading(false);
                props.handleNext();
            })
            .catch((error) => {
                console.error(error);
                setWasSuccessfully(false);
            })
    }


    async function generatePDF() {
        let legalTransactionFullObject = {
            idLegalTransaction: -1,
            idLegalTransactionState: 1,
            idLegalTransactionType: 6,
            idContact: -1,
            ContactObject: {
                ...personObject,
                CompanyObject: companyObject
            } as IContactFullObject,
            CompanyFormation: {
                ...props.companyFormationObject,
                ShareHolderArray: props.shareHolderArray,
                CompanyFormationShareArray: props.companyFormationShareArray,
                CompanyFormationHasShareHolderArray: props.shareHolderRelation,
                CompanyFormationAnswerArray: props.companyFormationAnswerArray,
                DocumentArray: props.documentArray
            } as ICompanyFormationFullObject
        } as ILegalTransactionFullObject
        fetch("/api/legaltransaction/createPdf", {
            method: "POST",
            headers: {
                "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                "Content-type": "application/json"
            },
            body: JSON.stringify(legalTransactionFullObject)
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw Error(`ERROR GET: ${res.status}`)
                }
            })
            .then(res => {
            })
            .catch((error) => {
            })
    }

    const {t, i18n} = useTranslation();


    return (
        <>
            <Dialog
                open={isLoading}
            >
                <DialogTitle>{t("please_wait")}</DialogTitle>
                <DialogContent>
                    {t("ra_request_upload")}

                    <Grid
                        container
                        sx={{mt: 3}}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={3}>
                            {(wasSuccessfully)
                                ? <CircularProgress/>
                                : <Alert severity="error">{t("ra_summary_error")}</Alert>
                            }

                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={idShareHolder !== null} onClose={() => setIdShareHolder(null)} maxWidth="md" fullWidth>
                <DialogTitle>{(idShareHolder === null || idShareHolder === 0) ? <>{t("new_person")}</> : <>Person
                    bearbeiten</>}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}>
                        {(idShareHolder !== null) &&
                            <ShareHolderEdit
                                idShareHolder={idShareHolder}
                                handleClose={() => setIdShareHolder(null)}
                                shareHolderArray={props.shareHolderArray}
                                setShareHolderArray={props.setShareHolderArray}
                                disableSelectCompanyType={
                                    props.shareHolderArray
                                        .find(x => x.idShareHolder === idShareHolder)
                                        ?.idBusinessForm === 1
                                }
                            />
                        }
                    </Box>
                </DialogContent>
            </Dialog>


            <Box sx={{mt: 5}}/>
            <Typography variant="h4" sx={{mb: 3}}>{t("conclusion")}</Typography>

            <Box sx={{mt: 5}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                {t("conclusion")}
            </Typography>
            <Grid container sx={{pl: 10}} spacing={2}>
                <Grid item sm={4}><b>{t("ra_conclusion_name")}</b></Grid>
                <Grid item sm={8}>{checkString(props.companyFormationObject.CompanyName)}</Grid>

                <Grid item sm={4}><b>{t("conclusion_ra_headquarter")}</b></Grid>
                <Grid item
                      sm={8}>{checkString(props.companyFormationObject.Street)} {checkString(props.companyFormationObject.StreetNr)}</Grid>

                <Grid item sm={4}></Grid>
                <Grid item sm={8}>
                    {(postCodeObject === undefined)
                        ? <>k.A.</>
                        : <>{checkString(postCodeObject.Postcode)} {checkString(postCodeObject.City)}</>
                    }

                </Grid>

                {(
                        props.companyFormationObject.AddressStreet !== null
                        || props.companyFormationObject.AddressStreetNr !== null
                        || props.companyFormationObject.Postcode_AddressidPostcode !== null
                    ) &&
                    <>

                        <Grid item sm={4}><b>{t("conclusion_ra_address")}</b></Grid>
                        <Grid item
                              sm={8}>{checkString(props.companyFormationObject.AddressStreet)} {checkString(props.companyFormationObject.AddressStreetNr)}</Grid>

                        <Grid item sm={4}></Grid>
                        <Grid item sm={8}>
                            {(addressPostCodeObject === undefined)
                                ? <>k.A.</>
                                : <>{checkString(addressPostCodeObject.Postcode)} {checkString(addressPostCodeObject.City)}</>
                            }

                        </Grid>
                    </>
                }
            </Grid>

            <CompanyFormationAnswerSummary
                companyFormationAnswerArray={props.companyFormationAnswerArray}
                companyFormationOptionArray={props.companyFormationOptionArray}
                companyFormationQuestionArray={props.companyFormationQuestionArray}
            />


            <Box sx={{mt: 10}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                {t("conclusion_ra_board")}
            </Typography>
            <Typography>
                {t("conclusion_ra_board_headline")} {
                (props.shareHolderRelation.length === 1) ? `dem ${props.shareHolderRelation[0].FreeText}.`
                    : props.shareHolderRelation.map((x, i) => (
                        (i + 1 === props.shareHolderRelation.length) ?
                            <span key={i}>dem {x.FreeText}.</span>
                            : <span key={i}>dem {x.FreeText}, </span>
                    ))
            }
            </Typography>

            <Table sx={{mt: 2}}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("funktion")}</TableCell>
                        <TableCell>{t("titre")}</TableCell>
                        <TableCell>{t("Gender")}</TableCell>
                        <TableCell>{t("first_name")}</TableCell>
                        <TableCell>{t("last_name")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.shareHolderArray.map(x =>
                        <TableRow key={x.idShareHolder}>
                            <TableCell sx={{color: "black"}}>
                                {
                                    props.shareHolderRelation
                                        .filter(y => y.idShareHolder === x.idShareHolder)
                                        .map(relation =>
                                            relation.FreeText
                                        )
                                        .join(", ")
                                }
                            </TableCell>
                            <TableCell>
                                {(x.idAcademicTitle === 1)
                                    ? ""
                                    : checkString(academicTitleArray.find(y => y.idAcademicTitle === x.idAcademicTitle)?.AcademicTitle)
                                }
                            </TableCell>
                            <TableCell>
                                {checkString(genderArray.find(y => y.idGender === x.idGender)?.Gender)}
                            </TableCell>
                            <TableCell>{x.FirstName}</TableCell>
                            <TableCell>{x.LastName}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => setIdShareHolder(x.idShareHolder)}><Search/></IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Box sx={{mt: 10}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                {t("conclusion_documents")}
            </Typography>
            <DocumentOverview
                allowRemove
                allowUpload
                documentArray={props.documentArray}
                setDocumentArray={props.setDocumentArray}
                title=""
            />

            <ClientInformation
                configObject={props.configObject}
                disableHasAccount={props.configObject.Module.find(x => x === "company_formation") === undefined}
                contactFullObject={{...personObject, CompanyObject: companyObject} as IContactFullObject}
                setContactFullObject={(localFullObject: IContactFullObject) => {
                    setPersonObject(localFullObject);
                    setCompanyObject(localFullObject.CompanyObject)
                }}
                handleBack={() => props.handleBack()}
                handleSave={handleSubmit}
                isTranslationEnabled={false}
            />

        </>
    )
}