import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";
import { IMortgagePerson } from "../../../../../../Interfaces/IPerson";
import { getMortgagePerson } from "../Components/Owner/MortgagePerson";
import CompanyModifiedBaseInformation from "../Components/Owner/CompanyModifiedBaseInformation";
import { PersonData } from "../Components/Owner/PersonData";
import { blue } from "@mui/material/colors";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  mortgageObject: IMortgage;
  setMortgageObject: Function;
}

const transcriptOptions = [
  "simple_transcription",
  "certified_copy",
  "simple_execution",
  "enforceable_copy",
] as const;

type TranscriptOption = (typeof transcriptOptions)[number];

export const MortgageBeneficiary: React.FC<IProps> = (props) => {
  const [isPrivatePerson, setIsPrivatePerson] = useState<boolean>(
    props.mortgageObject.BeneficiaryDetails?.isCompany ? false : true
  );
  const [creditor, setCreditor] = useState<IMortgagePerson>(
    props.mortgageObject.BeneficiaryDetails || getMortgagePerson(-1)
  );
  const [paragraph873, setParargraph873] = useState(
    props.mortgageObject.Paragraph
  );

  const initialCheckedOptions: Record<TranscriptOption, boolean> = {
    simple_transcription:
      props.mortgageObject.Transcripts?.includes("simple_transcription") ||
      false,
    certified_copy:
      props.mortgageObject.Transcripts?.includes("certified_copy") || false,
    simple_execution:
      props.mortgageObject.Transcripts?.includes("simple_execution") || false,
    enforceable_copy:
      props.mortgageObject.Transcripts?.includes("enforceable_copy") || false,
  };

  const [checkedOptions, setCheckedOptions] = useState(initialCheckedOptions);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedOptions({
      ...checkedOptions,
      [event.target.name as TranscriptOption]: event.target.checked,
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivatePerson(event.target.value === "true");
  };

  useEffect(() => {
    const selectedTranscripts = transcriptOptions.filter(
      (option) => checkedOptions[option]
    );

    props.setMortgageObject({
      ...props.mortgageObject,
      BeneficiaryDetails: { ...creditor, isCompany: !isPrivatePerson },
      Paragraph: paragraph873,
      Transcripts: selectedTranscripts,
    });
  }, [creditor, paragraph873, isPrivatePerson, checkedOptions]);

  /**
   *  <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Die Grundschuld muss nach{" "}
          <Link
            href="https://www.gesetze-im-internet.de/bgb/__873.html"
            target="_blank"
            rel="noopener"
            sx={{ 
                color: 'blue', // Linktextfarbe
                textDecoration: 'underline', // Unterstreichung aktivieren
                textDecorationColor: 'blue'  // Farbe der Unterstreichung ändern
              }}
          >
            §873 Abs. 2 BGB
          </Link>{" "}
          entgegengenommen werden.
        </Typography>
        <FormControl component="fieldset">
          <Alert severity={"info"} icon={false} sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paragraph873}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setParargraph873(event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  required
                />
              }
              label={<Typography component="span">Ich stimme zu.</Typography>}
            />
          </Alert>
        </FormControl>
   */

  return (
    <>
      <Box sx={{ mt: 5 }} />
      <Typography variant="h4">Begünstigter des Grundpfands</Typography>
      <Typography sx={{ mb: 3 }}>
        Geben Sie den Namen und die Adresse (ggf. Geburtsdatum) des Gläubigers
        (Kreditgebers) ein. Ist der Kreditgeber ein Unternehmen, geben Sie bitte
        den Firmennamen und die Adresse ein.
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={isPrivatePerson.toString()}
          onChange={handleRadioChange}
        >
          <Alert severity={"info"} icon={false} sx={{ mb: 2 }}>
            <FormControlLabel
              value="false"
              control={<Radio required />}
              label={
                <Typography component="span">
                  Gläubiger ist ein Unternehmen.
                </Typography>
              }
            />
          </Alert>
          <Alert severity={"info"} icon={false}>
            <FormControlLabel
              value="true"
              control={<Radio required />}
              label={
                <Typography component="span">
                  Gläubiger ist ein Privatperson.
                </Typography>
              }
            />
          </Alert>
        </RadioGroup>
      </FormControl>

      <Box sx={{ mt: 5 }} />
      {isPrivatePerson && (
        <PersonData
          beneficiaryObject={creditor}
          setBeneficiaryObject={setCreditor}
        />
      )}
      {!isPrivatePerson && (
        <CompanyModifiedBaseInformation
          beneficiaryObject={creditor}
          setBeneficiaryObject={setCreditor}
        />
      )}
      <Box sx={{ mt: 5 }} />
      <Typography sx={{ mb: 3 }}>
        Über welchen Betrag soll die Grundschuld bestellt werden?
      </Typography>
      <Grid item xs={6} sm={4}>
        <TextField
          label="Betrag der Grundschuld"
          size="small"
          fullWidth
          value={props.mortgageObject.AmountMortgage}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setMortgageObject({
              ...props.mortgageObject,
              AmountMortgage: event.target.value,
            })
          }
        />
      </Grid>
      <Box sx={{ mt: 5 }} />
      <Typography sx={{ mb: 3 }}>
        Wie hoch sind die sog. Grundschuldzinsen (in % bzw. von Hundert)?
      </Typography>
      <Grid item xs={6} sm={4}>
        <TextField
          label="Grundschuldzinsen"
          size="small"
          fullWidth
          value={props.mortgageObject.MortgageInterest}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setMortgageObject({
              ...props.mortgageObject,
              MortgageInterest: event.target.value,
            })
          }
        />
      </Grid>
      <Box sx={{ mt: 5 }} />
      <Typography sx={{ mb: 3 }}>
        Gibt es Nebenleistungen? Wenn keine Nebenleistungen vereinbart werden
        sollen, dann geben Sie bitte den Wert „0“ in das Feld ein.
      </Typography>
      <Grid item xs={6} sm={4}>
        <TextField
          label="Nebenleistungen"
          size="small"
          fullWidth
          value={props.mortgageObject.AdditionalServices}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setMortgageObject({
              ...props.mortgageObject,
              AdditionalServices: event.target.value,
            })
          }
        />
      </Grid>
      <Box sx={{ mt: 5 }} />

      <Box sx={{ mt: 5 }}>
        <Typography sx={{ mb: 3 }}>
          Welche Abschriften soll der Gläubiger erhalten?
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedOptions.simple_transcription}
                onChange={handleChange}
                name="simple_transcription"
              />
            }
            label="Einfache Abschrift"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedOptions.certified_copy}
                onChange={handleChange}
                name="certified_copy"
              />
            }
            label="Beglaubigte Abschrift"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedOptions.simple_execution}
                onChange={handleChange}
                name="simple_execution"
              />
            }
            label="Einfache Ausfertigung"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedOptions.enforceable_copy}
                onChange={handleChange}
                name="enforceable_copy"
              />
            }
            label="Vollstreckbare Ausfertigung"
          />
        </FormGroup>
       
      </Box>
      <Box sx={{ mt: 5 }} />
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          type="submit"
          variant="contained"
          onClick={() => props.handleNext()}
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
